.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background: #000;
}
.skills{
        text-align: center;
        padding: 4rem 0 2rem 0;
}

.card-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
}

.card{
    border: 1px solid #eee;
    color: #eee;
    text-align: center;
    padding: 1rem;
}

.card:hover{
    background-color: #2c2c2c;
}

.card h3{
    font-size: 1.4rem;
    padding: 1rem;
}

.card p{
    padding: 10px 0;
}

.bar{
    border-bottom: 1px solid #eee;
    width: 40%;
    margin: 1.5rem auto;
    display: block;
}

.btc{
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem 0;

}

.card .btn1{
    display: block;
    width: 80%;
    margin: 2rem auto;
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background: rgb(0, 162, 255);
    color: #222;
    border: 1px solid #e5d9e7;
    font-weight: 600;
    cursor: pointer;
}

.btn1:hover{
    background: rgba(255,255,255,0.2);
    color: #fff;
    transition: 0.3s;
  }


@media screen and (max-width: 740px) {
    .card-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
      
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
  }
  
  .logo{
    height: 100px;
  }
  .logo .logo-image {
    width: 300px;
    height: auto;
  }
  
  .header-bg {
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.5s;
  }
  
  .nav-menu {
    display: flex;
  }
  
  .nav-menu li {
    padding: 0 1.5rem;
  }
  
  .nav-menu li a {
    font-size: 29px;
    font-weight: 500;
  }

  .nav-menu li a:hover {
    color: rgb(0, 162, 255);
  }
  
  .hamburger {
    display: none;
  }
  
  @media screen and (max-width: 1040px) {
    .nav-menu {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.9);
      position: absolute;
      top: 0;
      right: -100%;
      z-index: -3;
      transition: 0.4s;
    }
  
    .nav-menu.active {
      right: 0;
    }
  
    .nav-menu li {
      padding: 1rem 0;
    }
  
    .nav-menu li a {
      font-size: 2rem;
    }
  
    .hamburger {
      display: initial;
    }
    
   .logo .logo-image {
      width: 200px;
      height: auto;
      margin-left: 0; /* Add this line to remove left margin */
    }
  }
  
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body{
  background: rgb(0,0,0,1);
  opacity:inherit;
}

h1, h4, p, a {
  color: #fff;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(0, 162, 255);
  color: #222;
  border: 1px solid #e5d9e7;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #e5d9e7;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover{
  background: rgba(255,255,255,0.2);
  color: #fff;
  transition: 0.3s;
}

.btn-light:hover{
  background: rgba(255,255,255,0.2);
  color: #fff;
  transition: 0.3s;
}

@media  screen and (max-width: 720px) {
  .btn{
  padding: 12px 11px;
  font-size: 0.8rem;
  }
  .btn-light{
  padding: 12px 11px;
  font-size: .8rem;
  }
}

/* Fade animation */
div[aria-current='page'] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
